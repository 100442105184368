import { GalleryPreviewImages, GalleryPreviewImagesProps } from "./Images";
import { GalleryPreviewLabels } from "./Labels";

interface GalleryPreviewProps extends GalleryPreviewImagesProps {
  showLabels?: boolean;
}

export function GalleryPreview(props: GalleryPreviewProps) {
  return (
    <>
      <GalleryPreviewImages {...props} />
      {props.showLabels && <GalleryPreviewLabels />}
    </>
  );
}
