import { Button } from "components/Form/Button";
import { Layout } from "components/Layout";
import { Fragment, memo, ReactNode } from "react";
import { AppRoutes } from "types";
import { ReactComponent as ErrorIcon } from "assets/error.svg";

export const Error = memo(function Error(props: { message?: ReactNode }) {
  return (
    <Layout
      className="space-y-10 grid h-full content-center justify-items-center"
      toolbar={<Button to={AppRoutes.home}>Retake</Button>}
    >
      <ErrorIcon />

      <div className="text-center space-y-2">
        <h3 className="font-medium">Something went wrong </h3>
        <p className="text-14 text-858494 leading-1.5">
          {props.message || (
            <Fragment>
              Sorry, there was a problem with your <br />
              upload, please try again..
            </Fragment>
          )}
        </p>
      </div>
    </Layout>
  );
});
