import { Dispatch, SetStateAction, useEffect, useRef } from "react";

export function useClickOutside<T extends HTMLElement>(
  state: Dispatch<SetStateAction<boolean>>
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        state(false);
      }
    };

    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, [state]);

  return ref;
}
