import clsx from "clsx";
import { useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = {
  variant?: "default" | "flat" | "link" | "danger";
  unstyled?: boolean;
} & (
  | ({ to?: never; href?: never } & JSX.IntrinsicElements["button"])
  | ({ href?: never; to?: string } & LinkProps)
  | ({ to?: never; href?: string } & JSX.IntrinsicElements["a"])
);

export function getButtonStyle(variant: Props["variant"] = "default"): string {
  return clsx(
    "py-4.5 px-9 rounded-9 leading-1.2 font-medium text-center transition-colors",
    "disabled:saturate-50 disabled:opacity-40 disabled:pointer-events-none",
    {
      "bg-3461fd text-white hover:bg-3461fd/80": variant === "default",
      "text-858494 hover:text-191c20": variant === "flat",
      "text-ff6848 hover:bg-ff6848/5": variant === "danger",
    }
  );
}

export function Button(props: Props) {
  const { variant = "default", unstyled = false, ...restProps } = props;

  const Component = useMemo(() => {
    if (restProps.to) {
      return Link;
    } else if (restProps.href) {
      return "a";
    } else {
      return "button";
    }
  }, [restProps]);

  const className = useMemo(
    () => clsx(unstyled ? "" : getButtonStyle(variant), props.className),
    [props.className, unstyled, variant]
  );

  return (
    // @ts-ignore
    <Component {...restProps} className={className}>
      {props.children}
    </Component>
  );
}
