import clsx from "clsx";
import { memo, ReactNode } from "react";
import { ReactComponent as MenuIcon } from "assets/menu.svg";
import { usePrivacyModalFull } from "hooks/usePrivacyModalFull";

type LayoutProps = {
  children?: ReactNode;
  className?: string;
  toolbar?: ReactNode;
  toolbarClassName?: string;
  wrapperClassName?: string;
  showTitle?: boolean;
  showPrivacyModal?: boolean;
};

export const Layout = memo(function Layout(props: LayoutProps) {
  const privacyModalFull = usePrivacyModalFull();

  return (
    <>
      {privacyModalFull.modal}
      <div
        className={clsx(
          "relative grid h-screen-safe gt-phone:h-[min(100vh,_896px)]",
          {
            "grid-rows-[auto_1fr_auto]":
              (props.showTitle || props.showPrivacyModal) && props.toolbar,
            "grid-rows-[auto_1fr]":
              (props.showTitle || props.showPrivacyModal) && !props.toolbar,
            "grid-rows-[1fr_auto]":
              props.toolbar && !(props.showTitle || props.showPrivacyModal),
          },
          props.wrapperClassName
        )}
      >
        {(props.showTitle || props.showPrivacyModal) && (
          <div className="sticky top-0 inset-x-0 flex items-center justify-between py-3 px-4">
            {props.showTitle && (
              <span className="text-20 leading-1.2 font-semibold">BioID</span>
            )}

            {props.showPrivacyModal && (
              <MenuIcon onClick={() => privacyModalFull.showModal()} />
            )}
          </div>
        )}

        <div
          className={clsx("px-4 pb-6 overflow-y-auto pt-8", props.className)}
        >
          {props.children}
        </div>

        {props.toolbar && (
          <div
            className={clsx(
              "sticky bottom-0 inset-x-0 px-4 py-6 grid gap-y-2",
              props.toolbarClassName
            )}
          >
            {props.toolbar}
          </div>
        )}
      </div>
    </>
  );
});
