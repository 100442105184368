import clsx from "clsx";
import { forwardRef, memo, ReactNode } from "react";

type Props = (
  | {
      id?: string;
      children?: ReactNode;
    }
  | { id?: never; children?: never }
) &
  JSX.IntrinsicElements["input"] & {
    checkboxClassName?: string;
    containerClassName?: string;
  };

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(props, ref) {
    const { children, containerClassName, checkboxClassName, ...restProps } =
      props;

    return (
      <label
        htmlFor={restProps.id}
        className={clsx(
          containerClassName,
          "flex items-center gap-3 text-14 text-858494 cursor-pointer select-none"
        )}
      >
        <input
          {...restProps}
          className={clsx(restProps.className, "peer")}
          type="checkbox"
          ref={ref}
          hidden
        />
        <span
          className={clsx(
            props.checkboxClassName,
            "flex-none w-6 h-6 rounded-full transition-all bg-3461fd/20",
            "peer-checked:bg-3461fd peer-checked:border-[3px] peer-checked:border-[white] peer-checked:border-double"
          )}
        />
        {children}
      </label>
    );
  })
);
