import clsx from "clsx";
import { useMemo } from "react";
import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from "react-router-dom";

interface LinkProps extends RouterLinkProps {}

export function Link(props: LinkProps) {
  const { to, className: _className, ...restProps } = props;

  const isAppen = useMemo(() => {
    try {
      return new URL(props.to as string).hostname !== window.location.hostname;
    } catch {
      return false;
    }
  }, [props.to]);

  const className = useMemo(
    () => clsx("text-3461fd hover:opacity-75", _className),
    [_className]
  );

  return isAppen ? (
    <a
      {...restProps}
      href={to as string}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <RouterLink {...restProps} to={to} className={className} />
  );
}
