import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { appLogger } from "lib/datadog";
import { AxiosError } from "axios";
import { uploadImage } from "lib/api";
import { Uploading } from "./components/Uploading";
import { Error } from "./components/Error";
import { Success } from "./components/Success";
import { useLocation } from "react-router-dom";

enum Screens {
  App,
  Upload,
  Success,
  Error,
}

export type UploadContextImages = Array<string>;

export interface UploadContextInterface {
  files: UploadContextImages;
  addFile: (file: File) => void;
  setFiles: (files: Array<File>) => void;
  resetFiles: () => void;
  removeFile: (idx: number) => void;
  captureId?: string;
  upload: () => Promise<void>;
}

export const UploadContext = createContext({} as UploadContextInterface);

export const UploadContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [screen, setScreen] = useState(Screens.App);
  const [files, setFiles] = useState<UploadContextImages>([]);
  const [captureId, setCaptureId] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);

  const _files = useMemo(() => {
    return files.filter((i) => typeof i === "string" && i.length > 0);
  }, [files]);

  const loc = useLocation();

  const addFile = useCallback(
    (file: File) =>
      setFiles((prev) => {
        if (file instanceof File) {
          return [...prev, URL.createObjectURL(file)];
        }

        return prev;
      }),
    []
  );

  const _setFiles = useCallback(
    (files: Array<File>) => setFiles(files.map(URL.createObjectURL)),
    []
  );

  const resetFiles = useCallback(() => setFiles([]), []);

  const removeFile = useCallback((idx: number) => {
    setFiles((prev) => {
      return prev.filter((image, _idx) => {
        if (typeof image !== "string" || image.length <= 0) {
          return false;
        }

        if (_idx === idx) {
          URL.revokeObjectURL(image);
          return false;
        }

        return true;
      });
    });
  }, []);

  useEffect(() => {
    setScreen(Screens.App);
  }, [loc.pathname]);

  const upload = useCallback(async () => {
    try {
      // NOTE: IOS don't allow use crypto for non localhost/https websites, so this is a workaround. (only for development)
      let captureId = "00000000-0000-0000-0000-000000000000";
      if (typeof window?.crypto?.randomUUID === "function") {
        captureId = window.crypto.randomUUID();
      }
      setCaptureId(captureId);

      setUploadProgress(0);
      setScreen(Screens.Upload);

      appLogger.log("Start image submission.");

      for await (const image of _files) {
        setUploadProgress(_files.indexOf(image));
        await uploadImage(image, captureId);
      }

      appLogger.log("Successful image submission.");
      setScreen(Screens.Success);
      _files.map(URL.revokeObjectURL);
    } catch (err) {
      console.error(err);
      setScreen(Screens.Error);
      appLogger.error("Error submitting image.", err as object);
      if (!(err instanceof AxiosError)) {
        return setErrorMessage("Something went wrong, please try again.");
      }

      if (err.response?.status === 403) {
        return setErrorMessage("There was an error with the upload (403).");
      }

      if (err.response?.headers["content-type"] === "application/xml") {
        const errMessage = err.response.data.match(/<Code>(.*)<\/Code>/);
        return setErrorMessage(
          `There was an error with the upload. Please help us report it: ${errMessage[1]}.`
        );
      }

      if (err.code === AxiosError.ERR_NETWORK) {
        return setErrorMessage(
          "We lost uplink to the network. Please check your connection and try again."
        );
      }
    } finally {
      setFiles([]);
    }
  }, [_files]);

  return (
    <UploadContext.Provider
      value={{
        files: _files,
        addFile,
        setFiles: _setFiles,
        resetFiles,
        removeFile,
        upload,
        captureId,
      }}
    >
      {screen === Screens.App && children}
      {screen === Screens.Upload && (
        <Uploading images={_files} progress={uploadProgress} />
      )}
      {screen === Screens.Success && <Success />}
      {screen === Screens.Error && <Error message={errorMessage} />}
    </UploadContext.Provider>
  );
};
