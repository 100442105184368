import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JWTPayload, decodeJwt } from "jose";
import { AppRoutes } from "types";

type JWT = JWTPayload & {
  registered: boolean;
  email: string;
  contributor?: string;
};

export interface AuthContextInterface {
  token: JWT | null | undefined;
  setToken: (token: string | null | undefined) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [token, _setToken] = useState<JWT | null | undefined>();
  const [init, setInit] = useState(false);
  const location = useLocation();
  const path = useMemo(
    () => location.pathname as AppRoutes,
    [location.pathname]
  );
  const navigate = useNavigate();

  const setToken = useCallback((token: string | null | undefined) => {
    try {
      if (typeof token !== "string" || token.length <= 0) {
        throw new Error("wrong token");
      }

      _setToken(decodeJwt(token) as JWT);
    } catch (err) {
      _setToken(null);
    }
  }, []);

  // ANCHOR: init token from localStorage
  useEffect(() => {
    if (token !== undefined) {
      return;
    }

    try {
      setToken(window.localStorage.getItem("token"));
    } catch (err) {
      _setToken(null);
    }
  }, [navigate, path, setToken, token]);

  useEffect(() => {
    // ANCHOR: redirect if path is not specified
    if (!Object.values(AppRoutes).includes(path)) {
      return navigate(AppRoutes.home);
    }

    if (token === undefined) {
      return;
    }

    if (
      token === null &&
      ![
        AppRoutes.login,
        AppRoutes.loginOrSignup,
        AppRoutes.logout,
        AppRoutes.signup,
      ].includes(path)
    ) {
      return navigate(AppRoutes.loginOrSignup);
    }

    if (
      token &&
      (token?.exp || 0) < Date.now() / 1000 &&
      [
        AppRoutes.home,
        AppRoutes.code,
        AppRoutes.capture,
        AppRoutes.orb,
        AppRoutes.collectionDetails,
      ].includes(path)
    ) {
      return navigate(AppRoutes.logout);
    }

    if (token && !token.registered && AppRoutes.signup !== path) {
      return navigate(AppRoutes.signup);
    }

    if (
      token &&
      token.registered &&
      [AppRoutes.login, AppRoutes.loginOrSignup, AppRoutes.signup].includes(
        path
      )
    ) {
      return navigate(AppRoutes.home);
    }

    setInit(true);
  }, [navigate, path, token]);

  const logout = useCallback(() => {
    navigate(AppRoutes.logout);
  }, [navigate]);

  if (!init) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ token, setToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
