import { Input } from "components/Form/Input";
import { Select } from "components/Form/Select";
import { OtherLabel } from "contexts/CollectionDetails";
import { useCollectionDetailsForm } from "contexts/CollectionDetails/useCollectionDetailsForm";
import { getUserEmail } from "lib/get-user-email";

export function GalleryPreviewLabels() {
  const { formState, labels, submit } = useCollectionDetailsForm();

  const watchDevice = formState.watch("device");
  const watchLabels = formState.watch("labels");
  const watchData = formState.watch("data");

  return (
    <div className="grid gap-4">
      <h3 className="text-858494 font-medium">Signed in as</h3>
      <span className="bg-f3f3f4 border-f4f7ff px-2.5 py-4 text-374151 rounded-xl">
        {getUserEmail()}
      </span>
      <h3 className="text-858494 font-medium">Selected labels</h3>
      {labels && (
        <form className="grid gap-2.5" onSubmit={(e) => e.preventDefault()}>
          {labels.devices && labels.devices.length > 0 && (
            <Select
              placeholder="Select label"
              options={Object.fromEntries(
                Object.entries(labels.devices).map(([, { key, label }]) => [
                  label,
                  key,
                ])
              )}
              value={watchDevice}
              onChange={(v) => {
                formState.clearErrors("device");
                formState.setValue("device", v as string, {
                  shouldValidate: true,
                });
                const camera_not_flipped = labels.devices.find(
                  (i) => i.key === v
                )?.camera_not_flipped;

                if (camera_not_flipped !== undefined) {
                  formState.setValue("camera_not_flipped", camera_not_flipped);
                }
                submit();
              }}
              renderListItem={({ value, label }) => `${value}: ${label}`}
            />
          )}

          {Object.entries(labels)
            .filter((i): i is [string, OtherLabel] => "labels" in i[1])
            .map(([id, field]) => (
              <Select
                key={id}
                options={field.labels}
                value={watchLabels?.[id]}
                withSearch={field.with_search}
                onChange={(v) => {
                  formState.clearErrors(`labels.${id}`);
                  formState.setValue(`labels.${id}`, v, {
                    shouldValidate: true,
                  });
                  submit();
                }}
                multiple={field.multiselect}
                renderValue={(i) => [i].flat().join(", ")}
                renderListItem={(i) => `${i.value}: ${i.label}`}
                listClassName="max-h-[40vh] overflow-y-auto"
              />
            ))}

          {watchData?.map?.((_, i) => (
            <Input
              {...formState.register(`data.${i}`)}
              onChange={(v) => {
                formState.setValue(`data.${i}`, v.currentTarget.value, {
                  shouldValidate: true,
                });

                submit();
              }}
            />
          ))}
        </form>
      )}
    </div>
  );
}
