import clsx from "clsx";
import { Button } from "components/Form/Button";
import { PSC } from "components/PCS";

export function CollectionAgreement(props: { onClose: () => void }) {
  return (
    <div
      className={clsx(
        "-m-4 -mt-6",
        "[&_.ps-contract-title]:!hidden [&_.ps-checkbox-container]:!hidden [&_.ps-contract-body]:!border-0 [&_.ps-contract]:!pb-0"
      )}
    >
      <PSC />
      <div className="p-4 bg-f4f7ff">
        <Button className="w-full" onClick={props.onClose}>
          Done
        </Button>
      </div>
    </div>
  );
}
