import { memo, useCallback, useContext } from "react";
import { Layout } from "components/Layout";
import { AppRoutes } from "types";
import { ReactComponent as Capture } from "assets/capture.svg";
import { ReactComponent as Orb } from "assets/orb.svg";
import { ReactComponent as Gallery } from "assets/gallery.svg";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { getUserEmail } from "lib/get-user-email";
import { Link } from "components/Link";
import { usePrivacyModal } from "hooks/usePrivacyModal";
import { CollectionDetailsContext } from "contexts/CollectionDetails";

export const Home = memo(function Home() {
  const { config } = useContext(CollectionDetailsContext);
  const navigate = useNavigate();
  const privacyModal = usePrivacyModal();

  const handleClickLink = useCallback(
    (link: AppRoutes) => {
      const goToLink = () => navigate(link);

      if (!privacyModal.accepted) {
        return privacyModal.show({
          onAccept: goToLink,
          onDecline: goToLink,
        });
      }

      goToLink();
    },
    [navigate, privacyModal]
  );

  return (
    <Layout
      showTitle
      showPrivacyModal
      toolbarClassName="justify-center"
      toolbar={
        <Link to="/logout" className="text-0f24e2">
          Log out
        </Link>
      }
    >
      {privacyModal.getModal()}
      <div className="space-y-10">
        <div className="grid justify-items-center text-center space-y-2.5">
          <span className="bg-191c20 p-5 rounded-full text-24 aspect-square leading-none">
            👻
          </span>
          <p className="font-medium text-center text-14">{getUserEmail()}</p>
        </div>
        <div className="space-y-4">
          <p className="font-medium text-center">
            Choose how you want to contribute today
          </p>

          <div className="grid gap-3">
            <button
              onClick={() => handleClickLink(AppRoutes.orb)}
              className={clsx(
                "grid gap-4 p-7 rounded-xl text-left transition-colors",
                "bg-transparent hover:bg-3461fd/5 border border-f4f7ff hover:border-3461fd"
              )}
            >
              <Orb />
              <span>Device Capture</span>
            </button>
            <button
              onClick={() => handleClickLink(AppRoutes.capture)}
              className={clsx(
                "grid gap-4 p-7 rounded-xl text-left transition-colors",
                "bg-transparent hover:bg-3461fd/5 border border-f4f7ff hover:border-3461fd"
              )}
            >
              <Capture />
              <span>Face capture</span>
            </button>
            {config?.enable_file_uploads && (
              <button
                onClick={() => handleClickLink(AppRoutes.batchUpload)}
                className={clsx(
                  "grid gap-4 p-7 rounded-xl text-left transition-colors",
                  "bg-transparent hover:bg-3461fd/5 border border-f4f7ff hover:border-3461fd"
                )}
              >
                <Gallery />
                <span>File upload</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
});
