import { datadogLogs, StatusType } from "@datadog/browser-logs";

datadogLogs.init({
  service: "bio-id",
  clientToken: process.env["REACT_APP_DATADOG_CLIENT_TOKEN"] as string,
  site: process.env["REACT_APP_DATADOG_SITE"] as string,
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
});

export const appLogger = datadogLogs.createLogger("appLogger", {
  level: StatusType.debug,
});
