import clsx from "clsx";
import { PropsWithChildren, ReactNode, memo, useMemo } from "react";

interface Props extends PropsWithChildren {
  as?: "label" | "span";
  label?: ReactNode;
  description?: ReactNode;
  descriptionClassName?: string;
}

export const Field = memo(function Field(props: Props) {
  const { as = "span", label, description, children } = props;

  const labelElement = useMemo(
    () => (
      <span className="font-medium text-858494 text-16">{props.label}</span>
    ),
    [props.label]
  );

  return (
    <div className="grid gap-3">
      {label && (
        <>
          {as === "label" && (
            <label className="grid gap-3">
              {labelElement}
              {children}
            </label>
          )}

          {as !== "label" && (
            <div className="grid gap-3">
              {labelElement}
              {children}
            </div>
          )}
        </>
      )}

      {!label && children}

      {description && (
        <span
          className={clsx("text-14 text-858494", props.descriptionClassName)}
        >
          {description}
        </span>
      )}
    </div>
  );
});
