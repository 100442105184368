import { PrivacyModal, PrivacyModalVariant } from "components/PrivacyModal";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const STORAGE_KEY = "privacy-statement-accepted";

export function usePrivacyModal() {
  const [show, setShow] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [onAccept, setOnAccept] = useState<() => void>();
  const [onDecline, setOnDecline] = useState<() => void>();

  // NOTE: initial set state
  useEffect(() => {
    setAccepted(window.sessionStorage.getItem(STORAGE_KEY) === "true");
  }, []);

  const handleAccept = useCallback(() => {
    window.sessionStorage.setItem(STORAGE_KEY, "true");
    setAccepted(true);
    setShow(false);
    onAccept?.();
  }, [onAccept]);

  const handleDecline = useCallback(() => {
    setShow(false);
    onDecline?.();
  }, [onDecline]);

  const reset = useCallback(() => {
    window.sessionStorage.removeItem(STORAGE_KEY);
    setAccepted(false);
  }, []);

  const getModal = useCallback(
    (variant?: PrivacyModalVariant) =>
      createPortal(
        <PrivacyModal
          show={show}
          onClose={() => setShow(false)}
          onAccept={handleAccept}
          onDecline={handleDecline}
          textVariant={variant}
        />,
        document.querySelector("#root") as Element
      ),
    [handleAccept, handleDecline, show]
  );

  const _show = useCallback(
    (options?: { onAccept?: () => void; onDecline?: () => void }) => {
      setOnAccept(() => options?.onAccept);
      setOnDecline(() => options?.onDecline);
      setShow(true);
    },
    []
  );

  return {
    getModal,
    accepted,
    show: _show,
    reset,
  };
}
