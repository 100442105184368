import { decodeJwt } from "jose";

export const getUserEmail = () => {
  try {
    const payload = decodeJwt(localStorage.getItem("token") || "");
    return (payload.email as string) ?? "Unknown user";
  } catch (err) {
    return "Unknown user";
  }
};
