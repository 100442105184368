import { Button } from "components/Form/Button";
import { Input } from "components/Form/Input";
import { Layout } from "components/Layout";
import { Field } from "components/Form/Field";
import { Radio } from "components/Form/Radio";
import { apiSignup } from "lib/api";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppRoutes, SignupScreens } from "types";
import { Checkbox } from "components/Form/Checkbox";
import { Select } from "components/Form/Select";
import { PSC } from "components/PCS";
import { toast } from "react-toastify";
import { AuthContext } from "contexts/AuthContext";

enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
  "Prefer not to say" = "unknown",
}

enum Ethnicity {
  Black = "black",
  White = "white",
  "Middle Eastern" = "middle_eastern",
  Hispanic = "hispanic",
  "South Asian" = "south_asian",
  "East Asian" = "east_asian",
  "Southeast Asian" = "southeast_asian",
  Other = "other",
  "Prefer not to say" = "unknown",
}

type FormValue = {
  firstname: string;
  lastname: string;
  mothers_name: string;
  fathers_name: string;
  date_of_birth: string;
  gender: Gender;
  ethnicity: Array<Ethnicity>;
  agreement: boolean;
  legal_status: "adult" | "minor";
  legal_guards?: "one" | "two";
  legal_guards_name_one?: string;
  legal_guards_name_two?: string;
  legal_guards_agreement_one?: boolean;
  legal_guards_agreement_two?: boolean;
};

export const Information = memo(function SignUp(props: {
  setStep: Dispatch<SetStateAction<SignupScreens>>;
}) {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { isValid },
    watch,
  } = useForm<FormValue>({
    defaultValues: {
      legal_status: "adult",
      legal_guards: "two",
      legal_guards_agreement_one: false,
      legal_guards_agreement_two: false,
    },
  });

  const watchLegalStatus = watch("legal_status");
  const watchLegalGuards = watch("legal_guards");
  const watchLegalGuardsAgreementOne = watch("legal_guards_agreement_one");
  const watchLegalGuardsAgreementTwo = watch("legal_guards_agreement_two");
  const watchGender = watch("gender");
  const watchEthnicity = watch("ethnicity");

  useEffect(() => {
    if (watchLegalStatus === "minor") {
      setValue("legal_guards", "two");
    }

    if (watchLegalStatus === "adult") {
      unregister("legal_guards");
      unregister("legal_guards_name_one");
      unregister("legal_guards_name_two");
    }
  }, [register, setValue, unregister, watchLegalStatus]);

  useEffect(() => {
    if (watchLegalGuards === "one") {
      unregister("legal_guards_name_two");
      setValue("legal_guards_agreement_two", false);
      setValue("legal_guards_name_two", "");
    }
  }, [register, setValue, unregister, watchLegalGuards]);

  const onSubmit = useCallback<SubmitHandler<FormValue>>(
    async (data) => {
      if (isValid) {
        setLoading(true);
        try {
          const token = await apiSignup({
            ...data,
            ethnicity: JSON.stringify(data.ethnicity),
          });

          window.localStorage.setItem("token", token);
          setToken(token);

          navigate(AppRoutes.home, { replace: true });
        } catch (err) {
          console.log("Error while signup", err);
          toast.error("Something went wrong, please try again later.");
        } finally {
          setLoading(false);
        }
      }
    },
    [isValid, navigate, setToken]
  );

  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const TriggerEl = triggerRef.current;
    if (!TriggerEl) {
      return;
    }

    const handler: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        setValue("agreement", true, { shouldValidate: true });
      }
    };
    const io = new IntersectionObserver(handler);
    io.observe(TriggerEl);

    return () => {
      io.unobserve(TriggerEl);
      io.disconnect();
    };
  }, [setValue]);

  return (
    <form className="contents" onSubmit={handleSubmit(onSubmit)}>
      <Layout
        className="space-y-12"
        toolbarClassName="bg-white"
        toolbar={
          <>
            <Button type="submit" disabled={!isValid || loading}>
              {loading ? "Loading ..." : "Continue"}
            </Button>
            <Button
              variant="flat"
              onClick={() => {
                window.localStorage.removeItem("token");
                props.setStep(SignupScreens.Email);
              }}
            >
              Back
            </Button>
          </>
        }
      >
        <div className="space-y-3 text-center">
          <h1 className="text-24 font-semibold leading-1.2 ">
            Let&apos;s get you signed up!
          </h1>

          <div className="space-y-2 text-858494">
            <p>
              To match the previously captured data, please provide us
              information below
            </p>
          </div>
        </div>

        <div className="text-left w-full space-y-6">
          <Field label="First name" as="label">
            <Input
              disabled={loading}
              id="firstName"
              {...register("firstname", { required: true, minLength: 2 })}
            />
          </Field>

          <Field label="Last name" as="label">
            <Input
              disabled={loading}
              id="lastName"
              {...register("lastname", { required: true, minLength: 2 })}
            />
          </Field>

          <Field label="Initial of Mothers's First Name" as="label">
            <Input
              disabled={loading}
              id="motherName"
              maxLength={1}
              {...register("mothers_name", { required: true })}
            />
          </Field>

          <Field label="Initial of Father's First Name" as="label">
            <Input
              disabled={loading}
              id="fatherName"
              maxLength={1}
              {...register("fathers_name", { required: true })}
            />
          </Field>

          <Field label="Date of Birth (month and year only)" as="label">
            <Input
              disabled={loading}
              id="dob"
              type="month"
              placeholder="MM/YYYY"
              {...register("date_of_birth", { required: true })}
              className="w-full"
              onChange={(e) => {
                const v = e.target.value;
                if (v.length === 2) {
                  e.target.value = `${v}/`;
                }
                e.target.value = v.slice(0, 7);
              }}
              onKeyDown={(e) => {
                if (!/[\d/]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Field>

          <div className="p-6 text-858494 border rounded-xl border-f4f7ff">
            In order to ensure that our biometric models are trained on a non
            discriminatory and diverse data set, Tools for Humanity Corp and
            GmbH (joint-controllers) would like to assign your ethnicity to you
            image data. You can withdraw your consent at any time in the app.
          </div>

          <Field label="Select your gender">
            <Select
              options={Gender}
              value={watchGender}
              renderValue={(v) =>
                Object.entries(Gender).find((i) => i[1] === v)?.[0]
              }
              onChange={(v) => {
                setValue("gender", v as Gender, {
                  shouldValidate: true,
                });
              }}
            />
          </Field>

          <Field label="Select your ethnicity">
            <Select
              multiple
              options={Ethnicity}
              value={watchEthnicity}
              renderValue={(v) => {
                return v
                  .map(
                    (i) =>
                      Object.entries(Ethnicity).find((j) => j[1] === i)?.[0]
                  )
                  .join(", ");
              }}
              onChange={(v) => {
                setValue("ethnicity", v as Ethnicity[], {
                  shouldValidate: true,
                });
              }}
            />
          </Field>

          <Field label="Legal Status">
            <div className="flex gap-5">
              <Radio
                type="radio"
                {...register("legal_status")}
                defaultValue="adult"
                label="Adult"
              />

              <Radio
                type="radio"
                {...register("legal_status")}
                defaultValue="minor"
                label="Minor"
              />
            </div>
          </Field>

          {watchLegalStatus === "minor" && (
            <Field label="Number of Legal Guardians">
              <div className="flex gap-5">
                <Radio
                  type="radio"
                  {...register("legal_guards")}
                  defaultValue="two"
                  label="Two"
                />
                <Radio
                  type="radio"
                  {...register("legal_guards")}
                  defaultValue="one"
                  label="One"
                />
              </div>
            </Field>
          )}

          <PSC
            onDisplayed={() => register("agreement", { required: true })}
            onValid={() =>
              setValue("agreement", true, { shouldValidate: true })
            }
            onInvalid={() =>
              setValue("agreement", false, { shouldValidate: true })
            }
            onError={() =>
              setValue("agreement", true, { shouldValidate: true })
            }
          />

          {watchLegalStatus === "minor" && (
            <div className="grid gap-4 bg-f4f7ff p-4 rounded-xl">
              <p className="text-14 text-454545">
                By entering my name, I confirm that I am the legal guardian and
                understand and give my consent to the Data Collection Agreement
                on behalf of the minor I am representing.
              </p>

              <div className="grid gap-6">
                <Field
                  label="First Legal Guardian"
                  description={
                    <Checkbox
                      id="legalGuardsAgreementOne"
                      {...register("legal_guards_agreement_one", {
                        required: true,
                      })}
                      className="!bg-white border-2 border-8a898e"
                      checked={watchLegalGuardsAgreementOne === true}
                    >
                      I understand and agree to the Data Collection Agreement
                    </Checkbox>
                  }
                >
                  <Input
                    id="legalGuardsNameOne"
                    {...register("legal_guards_name_one", { required: true })}
                    className="!bg-white"
                  />
                </Field>

                {watchLegalGuards === "two" && (
                  <Field
                    label="Second Legal Guardian"
                    description={
                      <Checkbox
                        id="legalGuardsAgreementTwo"
                        {...register("legal_guards_agreement_two", {
                          required: true,
                        })}
                        className="!bg-white border-2 border-8a898e"
                        checked={watchLegalGuardsAgreementTwo === true}
                      >
                        I understand and agree to the Data Collection Agreement
                      </Checkbox>
                    }
                  >
                    <Input
                      id="legalGuardsNameTwo"
                      {...register("legal_guards_name_two", {
                        required: true,
                      })}
                      className="!bg-white"
                    />
                  </Field>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </form>
  );
});
