import axiosLib, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import ExifReader from "exifreader";
import bcrypt from "bcryptjs";
import { getPrismicCollectionDetails } from "./prismic";
import { cleanupExif } from "./cleanup-exif";

const axios = axiosLib.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

export class ApiError extends Error {
  status: number;
  code: string;
  detail: string;
  attr: string;

  constructor(response: AxiosResponse) {
    super(response.data.detail);
    this.status = response.status;
    this.code = response.data.code;
    this.detail = response.data.detail;
    this.attr = response.data.attr;
  }
}

if (window && window.localStorage.getItem("token")) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${window.localStorage.getItem("token")}`;
}

// NOTE: reference https://github.com/jshttp/mime-db/blob/master/db.json
const mimeToExt = (mime: string) => {
  return {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/webp": "webp",
    "video/quicktime": "mov",
    "video/mp4": "mp4",
    "video/3gpp": "3gp",
  }[mime];
};

interface VideoMetadata {
  PixelXDimension: {
    value: number;
    description: string;
  };
  PixelYDimension: {
    value: number;
    description: string;
  };
  "Image Width": {
    value: number;
    description: string;
  };
  "Image Height": {
    value: number;
    description: string;
  };
}

// NOTE: exif-like video dimensions
async function getVideoMetadata(file: File): Promise<VideoMetadata> {
  const res = await new Promise<[number, number]>((resolve, reject) => {
    const el = document.createElement("video");
    el.style.display = "none";
    el.src = URL.createObjectURL(file);
    const handleLoad = () => {
      const resolution: [number, number] = [el.videoWidth, el.videoHeight];
      el.removeEventListener("loadedmetadata", handleLoad);
      el.removeEventListener("error", reject);
      el.remove();
      resolve(resolution);
    };
    el.addEventListener("loadedmetadata", handleLoad);
    el.addEventListener("error", reject);
  });

  return {
    PixelXDimension: {
      value: res[0],
      description: res[0].toString(),
    },
    PixelYDimension: {
      value: res[1],
      description: res[1].toString(),
    },
    "Image Width": {
      value: res[0],
      description: `${res[0]}px`,
    },
    "Image Height": {
      value: res[1],
      description: `${res[1]}px`,
    },
  };
}

// NOTE: collect collection details
async function getCollectionDetails(): Promise<{
  collection_details?: Array<string>;
  exif?: object;
} | void> {
  const result: {
    collection_details?: Array<string>;
    exif?: object;
  } = {};

  try {
    const collectionDetailsStr =
      window.localStorage.getItem("collection_details");
    if (collectionDetailsStr?.length) {
      const stored = JSON.parse(collectionDetailsStr);

      // NOTE: clear collection_details if prismic data is differ than local
      const prismic = await getPrismicCollectionDetails();

      if (!prismic?.show_collection_details) {
        return;
      }

      result.collection_details = [];

      if (prismic.devices.length > 0) {
        result.collection_details.push(stored.device);

        if ("camera_not_flipped" in stored) {
          result.exif = {
            camera_not_flipped: stored.camera_not_flipped,
          };
        }
      }

      prismic.simple_labels.forEach((label) => {
        result.collection_details?.push(stored.labels[label.id!]);
      });

      result.collection_details = [
        ...result.collection_details,
        ...stored.data,
      ];

      // NOTE: unwrap arrays
      result.collection_details = result.collection_details.flat();

      // NOTE: extra clear of empty values
      result.collection_details = result.collection_details.filter(
        (i) => typeof i === "string"
      );

      return result;
    }
  } catch {}
}

export const uploadImage = async (image: string, capture_id: string) => {
  const fileBlob = await (await fetch(image)).blob();
  const mime = fileBlob.type;
  const ext = `.${mimeToExt(mime) || "jpg"}`;
  const isImage = mime.startsWith("image/");
  const file = new File([fileBlob], `file${ext}`, {
    type: mime,
  });

  let exif: ExifReader.Tags | VideoMetadata | undefined = undefined;

  if (isImage) {
    try {
      exif = await ExifReader.load(file);
    } catch (err) {
      console.log("Cannot read exif data", err);
    }
  } else {
    exif = await getVideoMetadata(file);
    if (exif.PixelXDimension.value > exif.PixelYDimension.value) {
      console.log(
        "Only portrait videos are accepted. Please select a different file."
      );
      toast.error(
        "Only portrait videos are accepted. Please select a different file."
      );
      return;
    }
  }

  const collectionDetails = await getCollectionDetails();

  const params: {
    file_extension: string;
    exif?: object;
    capture_id: string;
    collection_details?: Array<string>;
  } = {
    file_extension: ext,
    collection_details: collectionDetails?.collection_details,
    exif: cleanupExif({
      ...exif,
      ...collectionDetails?.exif,
    }),
    capture_id,
  };

  const apiUrl = isImage ? "/face_capture" : "/video_capture";

  const response = await axios.post(apiUrl, params, {
    headers: {
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    },
  });
  const { url, fields } = response.data;
  await axiosLib.postForm(url, { ...fields, file });
};

export const apiLogin = async (
  email: string,
  password: string
): Promise<string | never> => {
  try {
    const response = await axios.post("/login", {
      email,
      password: bcrypt.hashSync(password, 10),
    });
    return response.data.token;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new ApiError(err.response);
    }

    throw err;
  }
};

export const apiSignup = async (
  bio_data: Record<string, string | boolean>
): Promise<string | never> => {
  try {
    const response = await axios.post(
      "/signup",
      { bio_data },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.token;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new ApiError(err.response);
    }

    throw err;
  }
};
