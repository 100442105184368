import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import clsx from "clsx";
import { useClickOutside } from "hooks/useClickOutside";
import { ReactNode, useEffect, useMemo, useState } from "react";

type ItemEntry = { label: string; value: any };
type Value<T extends boolean> = T extends true ? Array<string> : string;

type SelectProps<M extends boolean> = {
  options: Record<string, any>;
  value: Value<M> | undefined;
  onChange: (value: Value<M>) => void;
  placeholder?: string;
  renderValue?: (v: Value<M>) => ReactNode;
  renderListItem?: (v: ItemEntry) => ReactNode;
  withSearch?: boolean;
  multiple?: M;
  listClassName?: string;
};

export function Select<M extends boolean>(props: SelectProps<M>) {
  const [value, setValue] = useState(props.value);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside<HTMLDivElement>(setIsOpen);

  useEffect(() => setValue(props.value), [props.value]);

  useEffect(() => {
    setSearch("");
  }, [isOpen]);

  const options = useMemo(
    () =>
      Object.entries(props.options)
        .map(([label, value]) => ({
          label,
          value,
        }))
        .filter((i) =>
          props.withSearch && search.length > 0
            ? i.label
                .toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim()) ||
              i.value
                .toLowerCase()
                .trim()
                .includes(search.toLocaleLowerCase().trim())
            : true
        ) as Array<ItemEntry>,
    [props.options, props.withSearch, search]
  );

  const renderValue = useMemo(() => {
    return (v: typeof props.value): ReactNode => {
      if (!v || (props.value?.length || 0) <= 0) {
        return props.placeholder || `Select label${props.multiple ? "s" : ""}`;
      }

      if (props.renderValue) {
        return props.renderValue(v);
      }

      return [v].flat().join(", ");
    };
  }, [props]);

  const renderListItem = useMemo((): Exclude<
    typeof props.renderListItem,
    undefined
  > => {
    if (props.renderListItem) {
      return props.renderListItem;
    }

    return (v) => v.label;
  }, [props]);

  return (
    <div className="relative" ref={ref}>
      <button
        className="flex justify-between items-center bg-f4f7ff rounded-xl px-2.5 py-3.5 w-full"
        type="button"
        onClick={() => setIsOpen((p) => !p)}
      >
        {renderValue(value)}
        <ChevronDown
          className={clsx("transition-transform", { "rotate-180": isOpen })}
        />
      </button>

      <div
        className={clsx(
          "hidden absolute top-full inset-x-0 bg-white rounded-xl overflow-hidden border border-r4f7ff mt-1.5",
          "shadow-f4f7ff/25 ![box-shadow:0_0_4px_0_var(--tw-shadow-color)] z-10",
          { "!grid": isOpen }
        )}
      >
        {props.withSearch && (
          <div>
            <input
              value={search}
              className="bg-f4f7ff px-2.5 py-3.5 w-full border-none outline-none"
              onChange={(v) => setSearch(v.target.value)}
              placeholder="Search labels"
            />
          </div>
        )}
        {options.map((item) => {
          return (
            <button
              key={item.value}
              type="button"
              onClick={() => {
                if (props.multiple) {
                  const value = (props.value || []) as Array<string>;

                  if (value.includes(item.value)) {
                    value.splice(value.indexOf(item.value), 1);
                  } else {
                    value.push(item.value);
                  }
                  props.onChange(value as Value<M>);
                } else {
                  props.onChange(item.value);
                  setIsOpen(false);
                }
              }}
              className="flex gap-3 items-center hover:bg-f4f7ff px-4 py-2.5 text-left border-b border-f4f7ff last:border-0"
            >
              {props.multiple && (
                <span
                  className={clsx(
                    "relative w-3 h-3 rounded-full bg-3461fd/20",
                    "after:absolute after:-inset-0.5 after:border after:border-transparent after:rounded-full",
                    {
                      "!bg-3461fd after:border-3461fd": props.value?.includes(
                        item.value
                      ),
                    }
                  )}
                />
              )}
              <span>{renderListItem(item)}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
