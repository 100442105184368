import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";

const animateVariants = {
  hide: {
    y: "100vh",
    opacity: 0,
  },
  show: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 50,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

interface BackdropProps extends PropsWithChildren {
  onClick: () => void;
}

export interface ModalProps {
  show?: boolean;
  onClose: () => void;
}

function Backdrop(props: BackdropProps) {
  return (
    <motion.div
      className="bg-black/50 fixed inset-0 grid items-center h-full p-6"
      onClick={props.onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.children}
    </motion.div>
  );
}

export function Modal(props: PropsWithChildren<ModalProps>) {
  return (
    <AnimatePresence>
      {props.show && (
        <Backdrop onClick={props.onClose}>
          <motion.div
            className={clsx("bg-white p-4 pt-6 rounded-xl overflow-hidden")}
            onClick={(e) => e.stopPropagation()}
            variants={animateVariants}
            initial="hide"
            animate="show"
            exit="exit"
          >
            {props.children}
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>
  );
}
