import clsx from "clsx";
import { Button, getButtonStyle } from "components/Form/Button";
import { Layout } from "components/Layout";
import { ChangeEvent, useCallback, useContext, useEffect } from "react";
import { AppRoutes } from "types";
import { Explanation, ExplanationIcons } from "components/Explanation";
import { GalleryPreview } from "components/GalleryPreview";
import { UploadContext } from "contexts/UploadContext";
import { usePrivacyModal } from "hooks/usePrivacyModal";
import { CollectionDetailsContext } from "contexts/CollectionDetails";
import { useNavigate } from "react-router-dom";

export function BatchUpload() {
  const {
    files: images,
    setFiles: setImages,
    resetFiles: resetImages,
    upload,
  } = useContext(UploadContext);
  const { config } = useContext(CollectionDetailsContext);
  const privacyModal = usePrivacyModal();
  const navigate = useNavigate();

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      if (event.target.files) {
        setImages(Array.from(event.target.files));
      }
    },
    [setImages]
  );

  useEffect(() => {
    if ((config?.enable_file_uploads || false) === false) {
      navigate(AppRoutes.home);
    }
  }, [config, navigate]);

  return (
    <Layout
      className="space-y-8"
      toolbar={
        <>
          {!privacyModal.accepted && (
            <Button onClick={() => privacyModal.show()}>
              Privacy Agreement
            </Button>
          )}
          {privacyModal.accepted && (
            <>
              {images.length <= 0 && (
                <>
                  <label className={clsx(getButtonStyle(), "cursor-pointer")}>
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/webp,video/mp4,video/quicktime"
                      multiple
                      onChange={handleChangeInput}
                      hidden
                    />
                    <span>Choose files</span>
                  </label>
                  <Button to={AppRoutes.home} variant="flat">
                    Cancel
                  </Button>
                </>
              )}
              {images.length > 0 && (
                <>
                  <Button onClick={upload}>Upload</Button>
                  <Button onClick={resetImages} variant="flat">
                    Choose another
                  </Button>
                </>
              )}
            </>
          )}
        </>
      }
    >
      <h1 className="text-24 font-semibold text-center">
        {images.length <= 0 && "Choose images"}
        {images.length > 0 && "Preview images"}
      </h1>

      {images.length > 0 && (
        <div className="text-858494 leading-5 text-center">
          Review images, videos, and labels carefully. <br />
          Correct any mismatches before uploading.
        </div>
      )}

      {images.length <= 0 && (
        <Explanation
          items={[
            {
              icon: ExplanationIcons.note,
              title: "Input collection details",
              description: (
                <p>
                  Add any relevant information you want to upload along with the
                  files. Ensure all details entered apply consistently across
                  all files uploaded in the session.
                </p>
              ),
            },
            {
              icon: ExplanationIcons.selfie,
              title: "Select files to upload",
              description: (
                <p>
                  Tap on the button to select images and/or video files you
                  intend to upload. Preview them and confirm the upload
                </p>
              ),
            },
            {
              icon: ExplanationIcons.bulb,
              title: "Requirements",
              description: (
                <ul className="leading-[18px] list-disc">
                  <li>Ensure all uploads are in portrait orientation.</li>
                  <li>
                    Videos must remain in portrait mode throughout their
                    duration.
                  </li>
                  <li>Limit video length to 15 seconds max.</li>
                  <li>Video files must not exceed 100MB.</li>
                </ul>
              ),
            },
          ]}
        />
      )}
      {images.length > 0 && <GalleryPreview isRemovable showLabels />}
    </Layout>
  );
}
