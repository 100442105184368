import { Button } from "components/Form/Button";
import { Input } from "components/Form/Input";
import { Layout } from "components/Layout";
import { memo, useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppRoutes, SignupScreens } from "types";
import { ApiError, apiLogin } from "lib/api";
import { ReactComponent as Ai } from "assets/ai.svg";
import { ReactComponent as Connection } from "assets/connection.svg";
import * as jose from "jose";
import { Field } from "components/Form/Field";
import { toast } from "react-toastify";
import { InputPassword } from "components/Form/InputPassword";

type FormValue = {
  email: string;
  password: string;
};

export const Email = memo(function Login(props: {
  setStep: (step: SignupScreens) => void;
}) {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setError,
  } = useForm<FormValue>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = useCallback<SubmitHandler<FormValue>>(
    async ({ email, password }) => {
      if (isValid) {
        setProcessing(true);
        try {
          const token = await apiLogin(email, password);

          if (token) {
            try {
              const payload = jose.decodeJwt(token);
              window.localStorage.setItem("token", token);
              if (!payload.registered) {
                props.setStep(SignupScreens.Information);
              } else {
                navigate(AppRoutes.home, { replace: true });
              }
            } catch (err) {}
          }
        } catch (err) {
          if (err instanceof ApiError) {
            if (err.attr) {
              if (err.attr === "password") {
                setError("password", {
                  type: "custom",
                  message:
                    "Invalid passphrase. Please check and try again or contact the campaign manager for help.",
                });
              } else {
                setError(err.attr as "email" | "password", {
                  type: "custom",
                  message: err.message,
                });
              }
            } else {
              toast.error("Unable to login, please try again later.");
            }
          }
        } finally {
          setProcessing(false);
        }
      }
    },
    [isValid, navigate, props, setError]
  );

  return (
    <form className="contents" onSubmit={handleSubmit(onSubmit)}>
      <Layout
        className="h-full text-center"
        toolbar={
          <>
            <Button disabled={!isValid || processing} type="submit">
              {processing ? "Loading ..." : "Get Started"}
            </Button>
            <Button to={AppRoutes.login} variant="flat">
              Access an existing BioID
            </Button>
          </>
        }
      >
        <div className="grid h-full space-y-12">
          <div className="grid self-start justify-items-center">
            <Ai />

            <h1 className="text-24 leading-1.2 font-semibold mt-4">
              Training data collection
            </h1>

            <p className="text-858494 mt-3">
              Please enter your email and the campaign passphrase provided to
              you to begin creating your BioID.
            </p>
          </div>

          <div className="text-left space-y-8">
            <Field
              label="Please enter your email"
              description={errors.email?.message}
              descriptionClassName={
                errors.password?.message ? "text-fd380f" : undefined
              }
              as="label"
            >
              <Input
                disabled={processing}
                type="email"
                id="email"
                autoCapitalize="off"
                autoComplete="email"
                onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)}
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,
                })}
              />
            </Field>

            <Field
              label="Enter campaign passphrase"
              description={errors.password?.message}
              descriptionClassName={
                errors.password?.message ? "text-fd380f" : undefined
              }
              as="label"
            >
              <InputPassword
                disabled={processing}
                id="password"
                autoCapitalize="off"
                autoComplete="password"
                {...register("password", { required: true })}
              />
            </Field>

            <ul className="space-y-3 [&_li]:flex [&_li]:gap-3 [&_svg]:flex-none [&_p]:text-14 [&_p]:text-858494">
              <li>
                <Connection />

                <p>
                  We use your email to make sure we can easily associate any
                  collected data in multiple sessions for proper algorithm
                  training and we can process any data deletion requests.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </form>
  );
});
