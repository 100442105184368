import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { CollectionDetailsContext } from ".";

export interface CollectionDetailsFormValue {
  device: string;
  camera_not_flipped: boolean;
  labels: Record<string, string | Array<string>>;
  data: Array<string>;
}

export function useCollectionDetailsForm(props?: {
  onSubmit?: (data: CollectionDetailsFormValue) => void;
}) {
  const { config } = useContext(CollectionDetailsContext);

  const defaultValues = useMemo(() => {
    let res = {};
    try {
      res = JSON.parse(window.localStorage.getItem("collection_details")!);
    } catch (err) {
    } finally {
      return {
        data: [],
        ...res,
      };
    }
  }, []);

  const formState = useForm<CollectionDetailsFormValue>({ defaultValues });

  const addManualLabel = useCallback(() => {
    formState.setValue("data", [...(formState.getValues("data") || []), ""], {
      shouldValidate: true,
    });
  }, [formState]);

  const submit = useCallback(() => {
    formState.clearErrors();
    let isError = false;
    const formValue = formState.getValues();

    if (
      config?.labels?.devices &&
      config.labels.devices.length > 0 &&
      !formValue.device
    ) {
      formState.setError("device", { message: "Device is required" });
      isError = true;
    }

    Object.entries(config?.labels || {}).forEach(([id, label]) => {
      const labelValue = formValue.labels?.[id];
      if (
        "required" in label &&
        label.required &&
        (!labelValue || labelValue.length <= 0)
      ) {
        formState.setError(`labels.${id}`, { message: "Label required" });
        isError = true;
      }
    });

    if (isError) {
      return;
    }

    const result = {
      device: formValue.device,
      labels: formValue.labels,
      data: formValue.data.filter((item) => item.length > 0),
      camera_not_flipped: formValue.camera_not_flipped,
    };

    window.localStorage.setItem("collection_details", JSON.stringify(result));
    props?.onSubmit?.(result);
  }, [formState, config, props]);

  return {
    formState,
    addManualLabel,
    labels: config?.labels,
    submit,
  };
}
