import clsx from "clsx";
import { ReactComponent as Close } from "assets/close.svg";
import { useContext, useEffect, useState } from "react";
import { UploadContext } from "contexts/UploadContext";

enum FILE_TYPES {
  image = "image",
  video = "video",
}

async function getFileType(src: string): Promise<FILE_TYPES> {
  const mime = (await (await fetch(src)).blob()).type;

  if (mime.startsWith("video/")) {
    return FILE_TYPES.video;
  }

  return FILE_TYPES.image;
}

export interface GalleryPreviewImagesProps {
  min?: number;
  className?: string;
  itemClassName?: string;
  imgClassName?: string;
  counterClassName?: string;
  isRemovable?: boolean;
}

export function GalleryPreviewImages(props: GalleryPreviewImagesProps) {
  const [files, setFiles] = useState<Array<{
    src: string;
    type: FILE_TYPES;
  } | null> | null>(null);
  const { files: _files, removeFile: removeImage } = useContext(UploadContext);

  useEffect(() => {
    const slicedImages: Array<string> = [
      ..._files,
      ...new Array(props.min).fill(null),
    ].slice(0, Math.max(props.min || 0, _files.length));

    (async () => {
      let result = [];

      for await (const src of slicedImages) {
        if (src === null) {
          result.push(null);
          continue;
        }

        result.push({ src, type: await getFileType(src) });
      }

      setFiles(result);
    })();
  }, [_files, props.min]);

  if (!files) {
    return null;
  }

  return (
    <div className={clsx("grid grid-cols-3 gap-4", props.className)}>
      {files.map((file, idx) => (
        <div
          className={clsx(
            "relative grid place-items-end bg-f5f7ff rounded-xl overflow-clip justify-items-center",
            props.itemClassName
          )}
          key={idx}
        >
          {file !== null && (
            <>
              {props.isRemovable && (
                <button
                  className="absolute top-2 right-2 text-191c20"
                  onClick={() => removeImage(idx)}
                >
                  <Close />
                </button>
              )}
              {file.type === FILE_TYPES.image && (
                <img
                  className={clsx(
                    "aspect-[.79] object-cover",
                    props.imgClassName
                  )}
                  src={file.src}
                  alt=""
                />
              )}
              {file.type === FILE_TYPES.video && (
                <video
                  className={clsx(
                    "aspect-[.79] object-cover",
                    props.imgClassName
                  )}
                  src={file.src}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
