import { Modal, ModalProps } from "components/Modal";
import { ReactComponent as Ai } from "assets/ai.svg";
import { Button } from "../Form/Button";
import { useState } from "react";
import { Agreement } from "./Agreement";
import { CollectionAgreement } from "./CollectionAgreement";
import { CopyImages } from "./CopyImages";
import { DeleteGenderAndEthnicity } from "./DeleteGenderAndEthnicity";
import { DeleteAll } from "./DeleteAll";

interface PrivacyModalProps extends ModalProps {}

enum Screens {
  modal,
  agreement,
  collectionAgreement,
  copyImages,
  deleteGenderAndEthnicity,
  deleteAll,
}

const buttons = [
  [Screens.agreement, "Privacy Agreement"],
  [Screens.collectionAgreement, "Data Collection Agreement "],
  [Screens.copyImages, "Request copy of image data"],
  [Screens.deleteGenderAndEthnicity, "Delete gender & ethnicity data"],
  [Screens.deleteAll, "Request a full data deletion "],
] as const;

export function PrivacyModalFull(props: PrivacyModalProps) {
  const [screen, setScreen] = useState<Screens>(Screens.modal);

  return (
    <Modal
      show={props.show}
      onClose={() => {
        if (screen !== Screens.modal) {
          setScreen(Screens.modal);
        } else {
          props.onClose();
        }
      }}
    >
      {screen === Screens.modal && (
        <div className="grid gap-10">
          <div className="grid gap-[29px]">
            <Ai className="justify-self-center" />
            <div className="flex flex-col gap-2">
              {buttons.map((button, idx) => (
                <Button
                  key={idx}
                  onClick={() => setScreen(button[0])}
                  variant="flat"
                  className="border border-f4f7ff"
                >
                  {button[1]}
                </Button>
              ))}
            </div>
          </div>

          <Button onClick={props.onClose}>Close</Button>
        </div>
      )}
      {screen === Screens.agreement && (
        <Agreement onClose={() => setScreen(Screens.modal)} />
      )}
      {screen === Screens.collectionAgreement && (
        <CollectionAgreement onClose={() => setScreen(Screens.modal)} />
      )}
      {screen === Screens.copyImages && (
        <CopyImages onClose={() => setScreen(Screens.modal)} />
      )}
      {screen === Screens.deleteGenderAndEthnicity && (
        <DeleteGenderAndEthnicity onClose={() => setScreen(Screens.modal)} />
      )}
      {screen === Screens.deleteAll && (
        <DeleteAll onClose={() => setScreen(Screens.modal)} />
      )}
    </Modal>
  );
}
