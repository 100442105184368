import { PrivacyModalFull } from "components/PrivacyModalFull";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";

export function usePrivacyModalFull() {
  const [show, setShow] = useState(false);

  const modal = createPortal(
    <PrivacyModalFull show={show} onClose={() => setShow(false)} />,
    document.querySelector("#root") as Element
  );

  const showModal = useCallback(() => setShow(true), []);

  return {
    modal,
    showModal,
  };
}
