import clsx from "clsx";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  memo,
} from "react";

export const Input = memo(
  forwardRef<
    HTMLInputElement,
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  >(function Input(props, ref) {
    return (
      <input
        {...props}
        ref={ref}
        className={clsx(
          "bg-f4f7ff rounded-xl h-12 px-4.5 py-3 placeholder:text-d1d3d4",
          props.className
        )}
      />
    );
  })
);
