import {
  BooleanField,
  GroupField,
  KeyTextField,
  PrismicDocument,
} from "@prismicio/client/src/types/value/document";

export enum AppRoutes {
  home = "/",
  login = "/login",
  signup = "/signup",
  code = "/code",
  capture = "/capture",
  orb = "/orb",
  logout = "/logout",
  loginOrSignup = "/login-or-signup",
  collectionDetails = "/collection-details",
  batchUpload = "/batch-upload",
}

export enum SignupScreens {
  Email,
  Information,
}

export type Contributor = string;

export type PrismicCollectionDetails = PrismicDocument<{
  show_camera_not_flipped: BooleanField;
  enable_file_uploads: BooleanField;
  show_collection_details: BooleanField;
  devices: GroupField<{
    key: KeyTextField;
    label: KeyTextField;
    camera_not_flipped: BooleanField;
  }>;
  simple_labels: GroupField<{
    id: KeyTextField;
    title: KeyTextField;
    multiselect: BooleanField;
    required: BooleanField;
    labels: KeyTextField;
    with_search: BooleanField;
  }>;
}>;
