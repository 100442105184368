import clsx from "clsx";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  memo,
} from "react";

type InputType = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "className"
>;

interface Props extends InputType {
  label: string;
}

export const Radio = memo(
  forwardRef<HTMLInputElement, Props>(function Radio(props, ref) {
    const { label, ...restProps } = props;
    return (
      <label className="flex items-center gap-2 group">
        <input
          {...restProps}
          className={"peer"}
          type="radio"
          hidden
          ref={ref}
        />

        <span
          className={clsx(
            "w-6 h-6 rounded-full transition-all bg-3461fd/20",
            "peer-checked:bg-3461fd peer-checked:border-[3px] peer-checked:border-[white] peer-checked:border-double"
          )}
        />
        <span className="font-medium text-858494">{label}</span>
      </label>
    );
  })
);
