import { Button } from "components/Form/Button";
import { Modal, ModalProps } from "components/Modal";
import { Link } from "./Link";

export enum PrivacyModalVariant {
  full,
  orb,
}

interface PrivacyModalProps extends ModalProps {
  onAccept?: () => void;
  onDecline?: () => void;
  textVariant?: PrivacyModalVariant;
}

export function PrivacyModal(props: PrivacyModalProps) {
  return (
    <Modal show={props.show} onClose={props.onClose}>
      <div className="text-center space-y-6">
        <div className="space-y-2">
          <h4 className="font-rubik font-medium ">Privacy Agreement</h4>
          <div className="text-858494 text-14 leading-[21px] px-3.5">
            We ask you for your consent to collect your iris and face images to
            train our algorithms with that data. We also ask you for consent to
            transfer that data under appropriate safeguards to the European
            Union and the USA. Please read and accept the &nbsp;
            <Link to="https://worldcoin.pactsafe.io/rkuawsvk5.html#contract-yxxy0cnul">
              data collection agreement
            </Link>
            &nbsp; to continue.
          </div>
        </div>

        <div className="flex gap-2 [&>button]:w-full [&>button]:text-14 [&>button]:py-3">
          <Button
            variant="flat"
            onClick={props.onDecline}
            className="text-858494"
          >
            Decline
          </Button>

          <Button onClick={props.onAccept}>Accept</Button>
        </div>
      </div>
    </Modal>
  );
}
