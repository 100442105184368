import { Button } from "components/Form/Button";
import { Layout } from "components/Layout";
import { memo, useState } from "react";
import { AppRoutes } from "types";
import { ReactComponent as Ai } from "assets/ai.svg";
import { ReactComponent as Login } from "assets/login.svg";
import { ReactComponent as Signup } from "assets/signup.svg";
import clsx from "clsx";

export const LoginOrSignup = memo(function LoginOrSignup() {
  const [link, setLink] = useState<AppRoutes>(AppRoutes.login);

  return (
    <Layout
      className="h-full text-center"
      toolbar={<Button to={link}>Continue</Button>}
    >
      <div className="grid h-full space-y-12">
        <div className="grid self-start justify-items-center">
          <Ai />

          <h1 className="text-24 leading-1.2 font-semibold mt-4">
            Training data collection
          </h1>

          <p className="text-858494 mt-3">
            Thanks for helping to improve our machine <br /> learning algorithms
          </p>
        </div>

        <div className="space-y-4">
          <p className="font-medium text-left text-858494">
            Choose how you'd like to proceed based on your BioID status.
          </p>

          <div className="grid gap-3">
            {[
              {
                link: AppRoutes.login,
                label: "Use existing BioID",
                icon: Login,
              },
              {
                link: AppRoutes.signup,
                label: "Create a new BioID",
                icon: Signup,
              },
            ].map((type, index) => (
              <button
                onClick={() => setLink(type.link)}
                key={index}
                className={clsx(
                  "flex justify-between items-center p-6 rounded-xl text-left transition-colors",
                  "bg-transparent hover:bg-3461fd/5 border border-f4f7ff hover:border-3461fd text-858494",
                  {
                    "!bg-3461fd/5 !border-3461fd !text-191c20":
                      type.link === link,
                  }
                )}
              >
                <span className="grid gap-4">
                  <type.icon />
                  <span>{type.label}</span>
                </span>

                <span
                  className={clsx(
                    "relative w-3 h-3 rounded-full bg-3461fd/20 transition-all",
                    "after:absolute after:-inset-[2px] after:rounded-full after:border after:transition-all after:border-transparent",
                    { "after:!border-3461fd !bg-3461fd": type.link === link }
                  )}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
});
