const BANNED_KEYS = ["dscm", "Device Model Number", "Thumbnail"];

export function cleanupExif(exif: Record<string, any>): Record<string, any> {
  const newExif = { ...exif };

  Object.keys(newExif).forEach((key) => {
    if (BANNED_KEYS.includes(key)) {
      delete newExif[key];
    }

    if (typeof newExif[key] === "object" && newExif[key] !== null) {
      cleanupExif(newExif[key]);
    }
  });

  return newExif;
}
