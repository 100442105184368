import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "types";

export function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate(AppRoutes.home, { replace: true });
    window.location.reload();
  }, [navigate]);

  return null;
}
