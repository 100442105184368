import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { Capture } from "./pages/Capture";
import { Login } from "./pages/Login";
import { Home } from "pages/Home";
import { SignUp } from "./pages/Signup";
import { AppRoutes } from "types";
import { Orb } from "pages/Orb";
import { ReactNode } from "react";
import { Logout } from "pages/Logout";
import { LoginOrSignup } from "pages/LoginOrSignup";
import { BatchUpload } from "pages/BacthUpload";
import { CollectionDetailsContextProvider } from "contexts/CollectionDetails";
import { UploadContextProvider } from "contexts/UploadContext";
import { AuthContextProvider } from "contexts/AuthContext";

const routes: Array<{ path: AppRoutes; element: ReactNode }> = [
  {
    path: AppRoutes.home,
    element: <Home />,
  },
  {
    path: AppRoutes.loginOrSignup,
    element: <LoginOrSignup />,
  },
  {
    path: AppRoutes.login,
    element: <Login />,
  },
  {
    path: AppRoutes.signup,
    element: <SignUp />,
  },
  {
    path: AppRoutes.orb,
    element: <Orb />,
  },
  {
    path: AppRoutes.capture,
    element: <Capture />,
  },
  {
    path: AppRoutes.logout,
    element: <Logout />,
  },
  {
    path: AppRoutes.batchUpload,
    element: <BatchUpload />,
  },
];

export function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        transition={Slide}
        position="top-center"
        limit={1}
        hideProgressBar
        autoClose={5000}
      />

      <div className="contents gt-phone:grid place-items-center w-screen h-screen items-center bg-black/10">
        <div className="contents overflow-hidden gt-phone:block gt-phone:rounded-3xl gt-phone:shadow-2xl bg-white max-w-[430px] max-h-[896px] w-full h-fill gt-phone:[clip-path:inset(0_round_24px)]">
          <AuthContextProvider>
            <CollectionDetailsContextProvider>
              <UploadContextProvider>
                <Routes>
                  {routes.map((route) => (
                    <Route {...route} key={route.path} />
                  ))}
                </Routes>
              </UploadContextProvider>
            </CollectionDetailsContextProvider>
          </AuthContextProvider>
        </div>
      </div>
    </BrowserRouter>
  );
}
